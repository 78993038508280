<template>
  <div class="thirdparty-login">
    <base-loading style="height: 100vh"></base-loading>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      token: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.checkToken();
  },
  methods: {
    ...mapActions(["setThirdpartyToken", "getUser", "logout", "setAlert"]),
    checkToken() {
      this.token = this.$route.query.token;
      if (this.token) {
        this.setThirdpartyToken(this.token);
        this.fetchUser();
      } else {
        this.setAlert({
          type: "error",
          message: "Token Not found",
        });
        this.$router.replace({ name: "landing" });
      }
    },
    fetchUser() {
      this.isLoading = true;
      this.getUser()
        .then(() => {
          
          this.setAlert({
            type: "success",
            message: `${
              this.user.name ? "Welcome back " + this.user.name : "Welcome to minigames"
            }`,
          });
          if (!this.$route.query.redirect) {
            this.$router.replace({ name: "dashboard" });
          } else {
            this.$router.replace(this.$route.query.redirect);
          }
        })
        .catch(() => {
          this.token = null;
          this.setAlert({
            type: "error",
            message: "your token is invalid or expired",
          });
          this.logout();
          this.$router.replace({ name: "landing" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
